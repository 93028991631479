import React from "react";
import { PropTypes } from "prop-types";
import { lookupHelp, lookupText } from "../utilities/TextLookups";
import convertDataFunc from "../utilities/convertDataFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Select from "./Select";
import RadioOptions from "./RadioOptions";
import DateSelects from "./DateSelects";

class Form extends React.Component {
	constructor (props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
	}

	handleCheck (event) {
		this.props.onCheckboxChange(event.target.name, event.target.value, event.target.checked);
	}

	handleChange (event) {
		if (event.target) {
			this.props.onFieldChange(event.target.name, event.target.value);
		} else {
			this.props.onFieldChange(event.name, event.value);
		}
	}

	render () {
		const fieldsJsx = Object.entries(this.props.requirements).map(key => {

			let requirementsName = key[0];
			let requirementsNameVal = key[1];
			let fieldLabel;
			let fieldType;
			let inputClasses = "input";
			let pattern;
			let extraParams;
			let eventHandler = this.handleChange;
			let fileCount = 0;

			let requiredField = requirementsNameVal.required;
			let fieldValue = requirementsName in this.props.fieldValues ? this.props.fieldValues[requirementsName] : "";
			fieldValue = fieldValue === null ? "" : fieldValue;

			switch (requirementsNameVal.type) {
				case "datetime":
					fieldType = "datetime-local";
					fieldValue = convertDataFunc(["system-datetime", fieldValue]);
					break;

				case "hidden":
					fieldType = "hidden";
					break;

				case "date":
					fieldType = "date";
					fieldValue = convertDataFunc(["system-date", fieldValue]);
					break;

				case "date-selects":
					fieldType = "date-selects";
					fieldValue = convertDataFunc(["date-parts", fieldValue]);
					break;

				case "MoneyAmount":
				case "int":
				case "float":
					fieldType = "number";
					break;

				case "Password":
				case "PasswordTry":
					fieldType = "password";
					// at least one lowercase, uppercase letter and a symbol
					// pattern = "((?=.*[a-z])(?=.*[A-Z])(?=.*[^\\w\\d\\s])(?=.*[\\W]).*)";
					break;

				case "Ip":
					fieldType = "text";
					pattern = "^((\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$";
					break;

				case "phone":
					fieldType = "tel";
					break;

				case "Email":
					fieldType = "email";
					break;

				case "file":
					fieldType = "file";
					break;

				case "Url":
					fieldType = "url";
					// pattern = 'https?://.+';
					break;

				case "bool":
					fieldType = "checkbox";
					inputClasses = "checkbox";
					requirementsNameVal.example_value = null;
					extraParams = {
						"checked": fieldValue
					};
					requiredField = null;
					eventHandler = this.handleCheck;
					break;

				case "LongString":
					fieldType = "textarea";
					inputClasses = "textarea";
					break;

				case "radio":
					fieldType = "radio";
					break;

				case "select":
					fieldType = "select";
					break;

				case "text":
				default:
					fieldType = "text";
			}

			if (requirementsNameVal?.form) {
				if (requirementsNameVal.form?.label) {
					fieldLabel = requirementsNameVal.form.label;
				}
				if (requirementsNameVal.form?.type) {
					fieldType = requirementsNameVal.form.type;
				}
				if (requirementsNameVal.form?.options) {
					requirementsNameVal.options = requirementsNameVal.form.options;
				}
			}

			let inputField = (
				<input type={fieldType}
					   className={inputClasses}
					   name={requirementsName}
					   maxLength={requirementsNameVal.max_length}
					   required={requiredField}
					   pattern={pattern}
					   placeholder={requirementsNameVal.placeholder ?
						   requirementsNameVal.placeholder : requirementsNameVal.example_value}
					   minLength={requirementsNameVal.min_length}
					   value={fieldValue}
					   onChange={eventHandler}
					   autoComplete={requirementsNameVal.autoComplete ? requirementsNameVal.autoComplete : "off"}
					   readOnly={requirementsNameVal.readOnly}
					   disabled={requirementsNameVal.disabled}
					   onClick={requirementsNameVal.onClick}
					   {...extraParams}
				/>
			);

			if (fieldType === "file") {
				inputField = (
					<input type="file" name="file" id="file" onChange={this.props.uploadFile} />
				);
			}

			if (fieldType === "textarea") {
				inputField = (
					<textarea className={inputClasses}
							  id={requirementsName}
							  name={requirementsName}
							  maxLength={requirementsNameVal.max_length}
							  required={requiredField}
							  placeholder={requirementsNameVal.example_value}
							  minLength={requirementsNameVal.min_length}
							  value={fieldValue}
							  onChange={eventHandler}
							  {...extraParams}
					/>
				);
			}

			if (fieldType === "date-selects") {
				inputField = (
					<DateSelects
						name={requirementsName}
						onChange={this.handleChange}
						formType={this.props.formType}
						required={requiredField}
						value={fieldValue}
					/>
				);
			}

			if (requirementsNameVal.options) {
				if (fieldType === "radio") {
					inputField = <RadioOptions name={requirementsName}
											   onChange={this.handleChange}
											   formType={this.props.formType}
											   required={requiredField}
											   options={requirementsNameVal.options}
											   value={fieldValue} />;
				}

				if (fieldType === "select") {
					// console.log(req);
					inputField = <Select name={requirementsName}
										 onChange={this.handleChange}
										 formType={this.props.formType}
										 fieldType={fieldType}
										 required={requiredField}
										 options={requirementsNameVal.options}
										 value={fieldValue} />;
				}
			}

			if (this.props.replacements && requirementsName in this.props.replacements) {
				const replacementObj = this.props.replacements[requirementsName];

				if (replacementObj === null) {
					// skip null replacement field
					return;
				}
				if (!replacementObj.requirements) {

				} else if (Object.keys(replacementObj.requirements).length === 0) {
					return (
						<div className={fieldType + " field"} key={key}>
							<label className="label">No {lookupText(this.props.formType, requirementsName)} exists
								yet</label>
							<a href={replacementObj.empty_fallback_link}
							   className="button is-link">Create {lookupText(this.props.formType, requirementsName)}</a>
						</div>
					);
				} else if (replacementObj.replacement_type === "select") {
					const options = replacementObj.requirements.map(option => (
						{
							[replacementObj.value_key]: option.name
						}));

					return <Select name={requirementsName}
								   onChange={this.handleChange}
								   formType={this.props.formType}
								   fieldType={fieldType}
								   required={requiredField}
								   options={options}
								   value={fieldValue} />;

				} else if (replacementObj.replacement_type === "file") {
					fileCount++;
					return (
						<div className={fieldType + " field"} key={key}>
							<label className="label"
								   htmlFor={requirementsName}>{lookupText(this.props.formType, requirementsName)}</label>
							<div className="file has-name is-fullwidth">
								<label className="file-label">
									<input className={"file-input file-input_" + fileCount} type="file" name="file"
										   id="file" onChange={() => {
										const fileInput = document.querySelector(".file-input_" + fileCount);
										if (fileInput.files.length > 0) {
											const fileLabel = document.querySelector(".filename_" + fileCount);
											fileLabel.textContent = fileInput.files[0].name;
											this.props.uploadFile();
										}
									}} />
									<span className="file-cta">
									  <span className="file-icon">
										  <FontAwesomeIcon icon={faUpload} />
									  </span>
									  <span className="file-label">
										  Choose a file…
									  </span>
									</span>
									<span className={"file-name filename_" + fileCount}>
									</span>
								</label>
							</div>
						</div>
					);
				} else if (replacementObj.replacement_type === "checkbox") {

					let checkboxes = replacementObj.requirements.map((option) => {
						const checked = fieldValue?.includes(option[replacementObj.value_key]);

						return (
							<li key={option[replacementObj.value_key]}>
								<label className="checkbox">
									<input
										type="checkbox"
										ref={option[replacementObj.value_key]}
										name={requirementsName}
										value={option[replacementObj.value_key]}
										onChange={this.handleCheck}
										checked={checked}
									/>
									{option.name}
								</label>
							</li>
						);
					});
					return (
						<div className={fieldType + " field"} key={key}>
							<h3 className="label">{lookupText(this.props.formType, requirementsName)}</h3>
							<ul className="control checkboxes-grid">
								{checkboxes}
							</ul>
						</div>
					);
				}
			}

			const label = this.props.hideLabels ? "" : (
				<label className="label"
					   id={fieldLabel ? "label-" + fieldLabel : "label-" + requirementsName}
					   htmlFor={fieldLabel ? fieldLabel : requirementsName}>
					{lookupText(this.props.formType, fieldLabel ? fieldLabel : requirementsName)}
				</label>
			);

			let insertAfter = <></>;
			if (this.props.insertAfter && requirementsName in this.props.insertAfter) {
				insertAfter = this.props.insertAfter[requirementsName];
			}

			return (
				<div key={key}>
					<div className={fieldType + " field"} id={"field-" + requirementsName}>
						{fieldType === "hidden" ? "" : label}
						{requirementsNameVal.labelIcon}
						<div className="control">
							{requirementsNameVal.icon}
							{lookupHelp(this.props.formType, requirementsName)}
							{inputField}
						</div>
						{!this.props.hideRequiredLabels && requiredField === true && fieldType !== "hidden" ? <p
							className="help is-danger required">Required</p> : ""}
					</div>
					{insertAfter}
				</div>
			);

		});

		const title = this.props?.title ? <h3 className="title">{this.props.title}</h3> : "";

		return (
			<div className={"form " + (this.props.className ?? "")}>
				{title}
				<form onSubmit={this.props.handleSubmit}>
					<div className="fields">
						{fieldsJsx}
					</div>

					{this.props.children}

					<nav className="is-flex is-justify-content-space-between">
						{this.props.buttons?.map(button => button)}
						<button
							type="submit"
							className="btn submit-btn"
							disabled={this.props.submitting}>
							{this.props?.submitText}
						</button>
					</nav>
				</form>
			</div>
		);
	}
}

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onCheckboxChange: PropTypes.func, 	// (event.target.name, event.target.value, event.target.checked);
	uploadFile: PropTypes.func,
	onFieldChange: PropTypes.func, 		// (event.target.name, event.target.value);
	title: PropTypes.string, 			// Displayed title of the form
	formType: PropTypes.string, 		// The type of fields in this form
	className: PropTypes.string,
	requirements: PropTypes.object.isRequired,
	fieldValues: PropTypes.object.isRequired,
	replacements: PropTypes.object,
	/* replace the requirement name with a groups of other requirements.  Eg.
		{
			'category_uuids': {
					replacement_type: 'checkbox',
					requirements: requirements_for_categories,
					value_key: 'uuid',
					empty_fallback_link: '/createCategory/',
					create_category_component
				},
			'network_uuid': {
					replacement_type: 'select',
					requirements: requirements_for_networks,
					value_key: 'uuid',
					empty_fallback_link: '/createNetwork/',
					create_network_component
				}
		 }
	 */
	buttons: PropTypes.array, // eg. [<CreateLink ...... />]
	hideLabels: PropTypes.bool,
	hideRequiredLabels: PropTypes.bool,
	submitting: PropTypes.bool,
	submitText: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Form;