import React, { Suspense } from "react";
import Home from "./Components/Pages/Home";
import ComingSoon from "./Components/Pages/ComingSoon";
import Loading from "./Components/Pages/Loading";
import Finished from "./Components/Pages/Finished";
import Subscribed from "./Components/Pages/Subscribed";
import EmptyLander from "./Components/Pages/EmptyLander";
import BonusLander from "./Components/Pages/BonusLander";
import Lander from "./Components/Pages/Lander";
import Lander202304 from "./Components/Pages/Lander202304";
import Survey from "./Components/Pages/Survey";

const Logout = React.lazy(() => import("./Components/Logout"));
const GetFacebookLoginUri = React.lazy(() => import("./Components/FacebookLogin"));
const Affiliates = React.lazy(() => import("./Components/Pages/Affiliates"));
const Terms = React.lazy(() => import("./Components/Pages/Terms"));
const Privacy = React.lazy(() => import("./Components/Pages/Privacy"));
const EarningsDisclaimer = React.lazy(() => import("./Components/Pages/EarningsDisclaimer"));
const SignUp = React.lazy(() => import("./Components/Pages/SignUp"));
const Login = React.lazy(() => import("./Components/Pages/Login"));
const HowItWorks = React.lazy(() => import("./Components/Pages/HowItWorks"));
const BonusOffers = React.lazy(() => import("./Components/Pages/BonusOffers"));
const AccountDetails = React.lazy(() => import("./Components/Pages/AccountDetails"));
const PaymentDetails = React.lazy(() => import("./Components/Pages/PaymentDetails"));
const EarningsPage = React.lazy(() => import("./Components/Pages/EarningsPage"));
const ReferAndEarn = React.lazy(() => import("./Components/Pages/ReferAndEarn"));
const ReferAndEarnLander = React.lazy(() => import("./Components/Pages/ReferAndEarnLander"));
const FaqsPage = React.lazy(() => import("./Components/Pages/FaqsPage"));
const Tasks = React.lazy(() => import("./Components/Pages/TasksPage"));
const Task = React.lazy(() => import("./Components/Pages/TaskPage"));
const Offer = React.lazy(() => import("./Components/Pages/OfferPage"));
const ResetPassword = React.lazy(() => import("./Components/Pages/ResetPasswordPage"));
const InBrainResult = React.lazy(() => import("./Components/Pages/InBrainResult"));
const SurveysRedirectPage = React.lazy(() => import("./Components/Pages/SurveysRedirectPage"));
const SurveysAdzukiPage = React.lazy(() => import("./Components/Pages/SurveysAdzukiPage"));
const SurveysCommsAdzukiPage = React.lazy(() => import("./Components/Pages/SurveysCommsAdzukiPage"));

export function serviceUrls () {
	return SERVICE_URLS; // from /conf/conf.js (overwritten for production during the build process)
}

export const Routes = {
	path: "/",
	name: "Home",
	exact: true,
	component: <Home />,
	children: [
		{
			path: "/logout",
			title: "Logout",
			exact: true,
			component: <Suspense fallback={<></>}><Logout /></Suspense>
		},
		{
			path: "/new",
			component: <Suspense fallback={<></>}><Lander202304 /></Suspense>
		},
		{
			path: "/tpl",
			name: "Home",
			component: <BonusLander lander={<Lander />} />
		},
		{
			path: "/tpltt",
			name: "Home",
			component: <BonusLander lander={(
				<Lander202304
					hidePreview={true}
					mainTitleJsx={(
						<h1 className="main-title">
							<strong>Earn Cash</strong><br /> in your spare time!</h1>
					)}
				/>
			)} />
		},
		{
			path: "/tpl-jobs",
			name: "Home",
			component: <BonusLander lander={<Lander202304 mainSubTitleJsx={<h2 className="main-subtitle">
				By completing free trials, and online tasks while between jobs
			</h2>} />} />
		},
		{
			path: "/tpls",
			name: "Home",
			component: <BonusLander lander={(
				<Lander202304
					hidePreview={true}
					mainTitleJsx={(
						<h1 className="main-title">
							<strong>Earn Cash</strong><br /> in your spare time!</h1>
					)}
				/>
			)} />
		},
		{
			path: "/tplb",
			name: "Home",
			component: <BonusLander lander={<Lander202304
				hidePreview={true}
				mainSubTitleJsx={<h2 className="main-subtitle">By completing simple online tasks for cash</h2>}
			/>} />
		},
		{
			path: "/tpl-new",
			name: "Home",
			component: <BonusLander lander={<Lander202304 />} />
		},
		{
			path: "/jobs",
			name: "Home",
			component: <BonusLander
				lander={<Lander />}
				mainTitleJsx={
					<h1 className="main-title">Earn Cashback
						<span className="smaller">whilst looking for a job</span>
					</h1>
				} />
		},
		{
			path: "/research-tribe",
			name: "Home",
			component: <BonusLander lander={<Lander />} partnerBrand="researchTribe" />
		},
		{
			path: "/my-side-gig",
			name: "Home",
			component: <BonusLander lander={<Lander202304
				partnerBrand="mySideGig"
				mainSubTitleJsx={<h2 className="main-subtitle">By completing surveys, free trials, sign ups, and online
					tasks</h2>}
			/>} />
		},
		{
			path: "/research-tribe-new",
			name: "Home",
			component: <BonusLander lander={<Lander202304
				partnerBrand="researchTribe"
				mainSubTitleJsx={<h2 className="main-subtitle">By completing surveys, free trials, sign ups, and online
					tasks</h2>}
			/>} />
		},
		{
			path: "/emmas-diary",
			name: "Home",
			component: <BonusLander lander={<Lander202304 partnerBrand="emmasDiary" />} />
		},
		{
			path: "/opinion-groups",
			name: "Home",
			component: <Lander partnerBrand="opinionGroups" />
		},
		{
			path: "/opinion-groups-new",
			name: "Home",
			component: <Lander202304 partnerBrand="opinionGroups" />
		},
		{
			path: "/fb",
			name: "Home",
			component: <Lander202304
				hidePreview={true}
				mainTitleJsx={
					<h1 className="main-title">Earn Cashback <span className="smaller">in your spare time!</span></h1>
				}
			/>
		},
		{
			path: "/fb10",
			name: "Home",
			component: <BonusLander lander={<Lander202304
				hidePreview={true}
				mainTitleJsx={
					<h1 className="main-title">Earn Cashback <span className="smaller">in your spare time!</span></h1>
				}
			/>}
			/>
		},
		{
			path: "/fb2",
			name: "Home",
			component: <Lander202304
				hidePreview={true}
				mainTitleJsx={
					<h1 className="main-title">Earn Cashback <span className="smaller">in your spare time!</span></h1>
				}
			/>
		},
		{
			path: "/signup",
			title: "Signup",
			component: <Suspense fallback={<></>}><SignUp /></Suspense>
		},
		{
			path: "/login",
			title: "Cashback Login With Facebook Or Email",
			component: <Suspense fallback={<></>}><Login /></Suspense>
		},
		{
			path: "/task/:rung_url_slug/:offer_url_slug/:done",
			component: <Suspense fallback={<></>}><Task /></Suspense>
		},
		{
			path: "/task/:rung_url_slug/:offer_url_slug",
			component: <Suspense fallback={<></>}><Task /></Suspense>
		},
		{
			path: "/task/:offer_url_slug",
			component: <Suspense fallback={<></>}><Task /></Suspense>
		},
		{
			path: "/tasks/:rung_url_slug",
			component: <Suspense fallback={<></>}><Tasks /></Suspense>
		},
		{
			path: "/tasks",
			component: <Suspense fallback={<></>}><Tasks /></Suspense>
		},
		{
			path: "/affiliates",
			title: "Affiliates",
			component: <Suspense fallback={<></>}><Affiliates /></Suspense>
		},
		{
			path: "/terms-and-conditions",
			title: "Terms & Conditions",
			component: <Suspense fallback={<></>}><Terms /></Suspense>
		},
		{
			path: "/privacy-notice",
			title: "Privacy Notice",
			component: <Suspense fallback={<></>}><Privacy /></Suspense>
		},
		{
			path: "/earnings-disclaimer",
			title: "Earnings Disclaimer",
			component: <Suspense fallback={<></>}><EarningsDisclaimer /></Suspense>
		},
		{
			path: "/how-it-works",
			title: "Learn How Cashback Works & How To Earn Real Money",
			component: <Suspense fallback={<></>}><HowItWorks /></Suspense>
		},
		{
			path: "/refer-and-earn",
			title: "Refer And Earn To Earn More Money!",
			component: <Suspense fallback={<></>}><ReferAndEarn /></Suspense>
		},
		{
			path: "/referred",
			title: "You have been referred! Welcome!",
			component: <Suspense fallback={<></>}><ReferAndEarnLander /></Suspense>
		},
		{
			path: "/faqs",
			title: "Cashback: FAQs - How It Works",
			component: <Suspense fallback={<></>}><FaqsPage /></Suspense>
		},
		{
			path: "/bonus-offers",
			title: "Bonus Offers Page",
			component: <Suspense fallback={<></>}><BonusOffers /></Suspense>
		},
		{
			path: "/my-account",
			title: "My Account",
			component: <Suspense fallback={<></>}><AccountDetails /></Suspense>
		},
		{
			path: "/payment-details",
			title: "Payment Details",
			component: <Suspense fallback={<></>}><PaymentDetails /></Suspense>
		},
		{
			path: "/earnings",
			title: "Earnings",
			component: <Suspense fallback={<></>}><EarningsPage /></Suspense>
		},
		{
			path: "/facebook-login",
			name: "Get User Login Uri",
			component: <Suspense fallback={<></>}><GetFacebookLoginUri /></Suspense>
		},
		{
			path: "/coming-soon",
			name: "Coming Soon... Cashback!",
			component: <Suspense fallback={<></>}><ComingSoon /></Suspense>
		},
		{
			path: "/finished",
			name: "Great work!",
			component: <Suspense fallback={<></>}><Finished /></Suspense>
		},
		{
			path: "/password-reset/:temp_uuid",
			title: "Reset password - Cashback",
			component: <Suspense fallback={<></>}><ResetPassword /></Suspense>
		},
		{
			path: "/password-reset",
			title: "Reset password - Cashback",
			component: <Suspense fallback={<></>}><ResetPassword /></Suspense>
		},
		{
			path: "/subscribe-thank-you",
			title: "Thanks for subscribing - Cashback",
			component: <Suspense fallback={<></>}><Subscribed /></Suspense>
		},
		{
			path: "/email-draw",
			title: "Email draw",
			component: <Suspense fallback={<></>}><EmptyLander /></Suspense>
		},
		{
			path: "/lemverify",
			title: "Cashback UK",
			component: (
				<Suspense fallback={<></>}>
					<EmptyLander hideContinueButton={true} hideFooter={true} />
				</Suspense>
			)
		},
		{
			path: "/how-to-video",
			title: "Cashback UK",
			component: (
				<Suspense fallback={<></>}>
					<EmptyLander hideFooter={true} />
				</Suspense>
			)
		},
		{
			path: "/welcome-survey",
			title: "Cashback survey",
			component: <Suspense fallback={<></>}><Survey /></Suspense>
		},
		{
			path: "/loading",
			component: <Loading />
		},
		{
			path: "/loading/:brand",
			component: <Loading />
		},
		{
			path: "/offer/:offer_url_slug",
			component: <Suspense fallback={<></>}><Offer /></Suspense>
		},
		{
			path: "/survey-result",
			component: <Suspense fallback={<></>}><InBrainResult /></Suspense>
		},
		{
			path: "/surveys",
			component: <Suspense fallback={<></>}><SurveysRedirectPage /></Suspense>
		},
		{
			path: "/cashback-surveys",
			title: "Daily Surveys",
			component: <Suspense fallback={<></>}><SurveysAdzukiPage /></Suspense>
		},
		{
			path: "/cashback-surveys-email",
			title: "Daily Surveys",
			component: <Suspense fallback={<></>}><SurveysCommsAdzukiPage /></Suspense>
		}
	]
};