import ApiFunc from "../Components/ApiCalls/ApiFunc";
import { local, session } from "./storage";
import { serviceUrls } from "../Routes";
import { trackOneSignalPrompt } from "./oneSignal";
import { getCookie } from "./cookies";

export function getUserUuid () {
	let userUuid = null;

	if (local.getItem("user_uuid")) {
		userUuid = local.getItem("user_uuid");
	}

	if (getCookie("impersonate_user_uuid")) {
		userUuid = getCookie("impersonate_user_uuid");
	}

	return userUuid;
}

export function getToken () {
	let token = null;

	if (local.getItem("user_token")) {
		token = local.getItem("user_token");
	}

	if (getCookie("held_admin")) {
		token = getCookie("held_admin");
	}

	return token;
}

export function getEmail () {
	let email = null;

	if (local.getItem("user_email")) {
		email = local.getItem("user_email");
	}

	return email;
}

export const allowedIps = ["192.168.65.3", "192.168.65.4", "185.37.176.32", "82.24.121.213",
	"82.34.129.52", "193.115.204.10", "86.179.208.187", "94.194.239.29",
	"109.157.246.77", "193.115.204.14"];

export const blockedIsps = [
	"adoard global reach llc",
	"media connective llp",
	"hydra communications ltd",
	"clouvider limited",
	"digitalocean llc",
	"digital ocean",
	"philippine long distance telephone company"
];

export function getMyIP () {
	ApiFunc("user/your-ip", "GET")
		.then(response => response.json())
		.then(data => {
			if (data.your_ip) {
				session.setItem("ip", data.your_ip);
				// if (!allowedIps.includes(data.your_ip) && !window.location.href.includes("/coming-soon")) {
				// 	window.location.href = "/coming-soon";
				// }
			}
		})
		.catch(error => {
			console.error("get your ip error:", error);
		});
}

export function updateTracking () {
	if (getCookie("held_admin") !== undefined) {
		return;
	}

	trackOneSignalPrompt();

	const trackFields = {
		user_agent: navigator.userAgent,
		url_path: window.location.pathname,
		url_search: window.location.search
	};

	if (local.getItem("track_uuid") !== null) {
		trackFields.track_uuid = local.getItem("track_uuid");
	}

	if (session.getItem("ip") !== null) {
		trackFields.ip = session.getItem("ip");
	}

	ApiFunc("tracking/track", "POST", trackFields)
		.then(response => response.json())
		.then(data => {
			if (data.track_info) {
				local.setItem("track_uuid", data.track_info.track_uuid);
				local.setItem("page_uuid", data.track_info.page_uuid);
			} else {
				local.removeItem("user_token");
				local.removeItem("user_uuid");
				local.removeItem("page_uuid");
				local.removeItem("track_uuid");
			}
		})
		.catch(error => {
			console.error("track_uuid error:", error);
		});
}

export function trackClick (offerUuid, onSuccess, clickId = null, externalId = null) {
	if (getCookie("held_admin") !== undefined) {
		return;
	}

	const pageUuid = local.getItem("page_uuid");

	if (!pageUuid || !offerUuid) {
		return;
	}

	const trackFields = {
		offer_uuid: offerUuid,
		page_uuid: pageUuid
	};

	if (clickId) {
		trackFields.click_id = clickId;
		trackFields.external_id = externalId;
	}

	ApiFunc("tracking/click", "POST", trackFields)
		.then(response => response.json())
		.then(data => {
			if (data.click_id) {
				onSuccess(data.click_id);
			}
		})
		.catch(error => {
			console.error("track click error:", error);
		});
}

export function trackImpression (ladderOfferUuids) {
	if (getCookie("held_admin") !== undefined) {
		return;
	}

	const pageUuid = local.getItem("page_uuid");

	if (!pageUuid || !ladderOfferUuids) {
		return;
	}

	const ids = Array.isArray(ladderOfferUuids) ? ladderOfferUuids : [ladderOfferUuids];

	const trackFields = {
		offer_uuid_impressions: ids,
		page_uuid: pageUuid
	};

	ApiFunc("tracking/impressions", "POST", trackFields)
		.then(response => response.json())
		.then(data => {
			if (!data.impressions_processing) {
				console.error("track impressions error");
			}
		})
		.catch(error => {
			console.error("track impressions error:", error);
		});
}

export function trackContent (content) {
	if (getCookie("held_admin") !== undefined) {
		return;
	}

	const trackUuid = local.getItem("track_uuid");
	if (!trackUuid) {
		return;
	}

	const trackFields = {
		track_uuid: trackUuid,
		content
	};

	ApiFunc("tracking/track", "POST", trackFields)
		.then(response => response.json())
		.then(data => {
			if (!data.track_info) {
				console.error("track content error");
			}
		})
		.catch(error => {
			console.error("track content error:", error);
		});
}

export function logout () {
	const requestDataOptions = {
		method: "POST",
		headers: new Headers({
			"Authorization": "Bearer " + getToken() ?? "",
			"Content-Type": "application/json"
		}),
		body: JSON.stringify({
			track_uuid: local.getItem("track_uuid") ?? null
		})
	};
	fetch(`${serviceUrls().serverUrl}/user/logout`, requestDataOptions)
		.then(() => {
			local.removeItem("user_token");
			local.removeItem("user_uuid");
			local.removeItem("user_email");
			local.removeItem("track_uuid");
			local.removeItem("ip");
			location.href = "/";
		});
}